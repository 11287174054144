<template>
    <div class="navbar__top">
        <AppBarNavigation />
    </div>
    <div class="header" data-aos="zoom-in-down">
        <h1>{{ project.details.header.title }}</h1>
        <img :src="project.details.header.image" :alt="project.name" class="project-cover">
        <p>{{ project.details.header.description }}</p>
        <div v-for="section in project.details.body" :key="section.title">
            <div data-aos="zoom-in-down">
                <h3>{{ section.title }}</h3>
                <img v-if="section.image != null" :src="section.image" :alt="section.title" class="project-cover">
                <video v-if="section.video != null" controls class="video">
                    <source :src="section.video" type="video/mp4" />
                </video>
                <p v-html="section.description"></p>
                <br>
            </div>
        </div>
        <a v-if="project.details.sourcecode != null" :href="project.details.sourcecode" target="_blank" rel="noopener noreferrer">click here to see the source code of the project</a>
    </div>
</template>
<script>
import AppBarNavigation from '@/components/AppBarNavigation.vue'
import Data from '@/assets/data/data.json'
export default {
    name: 'ProjectDetails',
    components: {
        AppBarNavigation,
    },
    data() {
        return {
            project: Data.projects.filter(project => project.slug == this.$router.currentRoute._value.params.name)[0]
        }
    },
    created() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
</script>

<style scoped>
.navbar__top {
    position: fixed;
    width: 100vw;
    margin: 0px;
    z-index: 9999;
}

.header {
    width: 100%;
    margin-top: 2vh;
    padding: 90px 135px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.header>* {
    margin: 20px auto;
}

h3 {
    text-align: center;
}

.header>div>div>* {
    margin: 20px auto;
}

.project-cover {
    width: 80%;
}

p {
    width: 80%;
    font-weight: 300;
}

.video {
    width: 70%;
    display: flex;
}

@media screen and (max-width: 500px) {
    .project-cover {
        width: 100%;
    }

    .video {
        width: 100%;
    }

    p {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
    }

}

@media screen and (max-width: 850px) {
    .header {
        height: fit-content;
        padding: 80px 50px;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .video {
        width: 100%;
    }

    p {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
    }
}
</style>