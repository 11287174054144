<template>
    <header v-bind:class="{ active: $store.state.isScroll || isOpen || this.$route.params.name != null }">
        <div v-if="this.$route.params.name == null">
            <h1>Elachagui<span>.</span></h1>
        </div>

        <div class="navigation__links">
            <nav v-if="this.$route.params.name == null">
                <ul>
                    <li>
                        <a href="home" v-scroll-to="'#home'">Home</a>
                    </li>
                    <li>
                        <a href="about" v-scroll-to="'#about'">About</a>
                    </li>
                    <li>
                        <a href="skills" v-scroll-to="'#skills'">Skills</a>
                    </li>
                    <li>
                        <a href="experience" v-scroll-to="'#experience'">Experiences</a>
                    </li>
                    <li>
                        <a href="projects" v-scroll-to="'#projects'">Projects</a>
                    </li>
                    <li>
                        <a href="certificate" v-scroll-to="'#certificate'">Certifications</a>
                    </li>
                    <!-- <li>
                            <a href="#">Projects</a>
                        </li>
                        <li>
                            <a href="#">Services</a>
                        </li> -->
                </ul>
            </nav>
            <nav v-else>
                <ul>
                    <li>
                        <router-link class="link" :to="{ name: 'Home' }">
                            Home
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="contact__button">
            <button v-if="this.$route.params.name == null">
                <a href="#" v-scroll-to="'#contact'">Contact</a>
            </button>
        </div>

        <div @click="isClicked" class="burger">
            <section class="top__line" v-bind:class="{ top__line__active: isOpen }"></section>
            <section class="middel__line" v-bind:class="{ middel__line__active: isOpen }"></section>
            <section class="bottom__line" v-bind:class="{ bottom__line__active: isOpen }"></section>
        </div>
    </header>
    <div class="navbar__scroll" v-bind:class="{ navbar__scroll__active: isOpen }">
        <nav class="burger__content" v-bind:class="{ burger__content__active: isOpen }">
            <ul v-if="this.$route.params.name == null">
                <li>
                    <a href="#" v-scroll-to="'#home'" @click="isClicked">Home</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#about'" @click="isClicked">About</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#skills'" @click="isClicked">Skills</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#experience'" @click="isClicked">Experiences</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#projects'" @click="isClicked">Projects</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#certificate'" @click="isClicked">Certifications</a>
                </li>
                <li>
                    <a href="#" v-scroll-to="'#contact'" @click="isClicked">Contact</a>
                </li>
            </ul>
            <ul v-else>
                <li>
                    <router-link class="link" :to="{ name: 'Home' }">
                        Home
                    </router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "AppBarNavigation",
    data: function () {
        return {
            isOpen: false
        }
    },
    methods: {
        isClicked: function () {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>

<style scoped>
.typer-color {
    color: white;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
}

.active {
    background: rgba(255, 255, 255, 0.5);
    margin-top: -2vh;
    backdrop-filter: blur(10px);
}

h1 {
    color: rgb(0, 0, 0);
}

h1>span {
    color: white;
}


nav>ul>li {
    display: initial;
}

nav>ul>li>a {
    text-decoration: none;
    color: black;
    margin-left: 20px;
}

.link {
    text-decoration: none;
    color: black;
    /* margin-left: 20px; */
}

nav>ul>li>a:first {
    margin-left: 0px;
}

.contact__button>button {
    padding: 8px 30px;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    background-color: black;
    border: none;
    cursor: pointer;
}

.contact__button>button>a {
    color: white;
    text-decoration: none;
}

.navigation__links {
    display: block;
}

.contact__button {
    display: block;
}

.burger {
    display: none;
}

.navbar__scroll {
    height: 0vh;
}

.burger__content {
    opacity: 0;
    transition: 0.3s;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 850px) {
    .navigation__links {
        display: none;
    }

    .contact__button {
        display: none;
    }

    .burger {
        display: block;
        width: 30px;
        height: 30px;
    }

    .burger>section {
        background-color: rgb(0, 0, 0);
    }

    .burger__content {
        display: none;
    }

    .burger__content__active {
        opacity: 1;
        transition: 3s;
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        width: 100vw;
    }

    .top__line {
        width: 23px;
        height: 2px;
        margin: 5px 0px;
        transform: rotate(0deg);
        transition: 1s;
    }

    .top__line__active {
        width: 25px;
        height: 2px;
        transform: rotate(585deg);
        margin-top: 10px;
        margin-bottom: 0px;
        transition: 1s;
    }

    .middel__line {
        width: 20px;
        height: 2px;
        opacity: 1;
        transition: 1s;
    }

    .middel__line__active {
        opacity: 0;
        transition: 1s;
    }

    .bottom__line {
        width: 25px;
        height: 2px;
        margin: 5px 0px;
        transform: rotate(0deg);
        transition: 1s;
    }

    .bottom__line__active {
        margin: -3px 0px;
        transform: rotate(-585deg);
        transition: 1s;
    }

    .navbar__scroll {
        height: 0vh;
        margin-top: 0px;
        transition: 1s;
    }

    .navbar__scroll__active {
        justify-content: center;
        margin-top: -20px;
        width: 100%;
        height: 80vh;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(2px);
        transition: 0.8s;
    }

    nav>ul>li {
        display: block;
        font-size: 30px;
        margin: 20px;
    }

    nav>ul>li>button {
        padding: 8px 35px;
        margin-top: 20px;
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        background-color: black;
        border: none;
        color: white;
        cursor: pointer;
    }

    nav>ul>li>a {
        color: black;
        margin-left: 0px;
    }

}

@media screen and (max-width: 500px) {
    header {
        padding: 20px 30px;
    }
}
</style>