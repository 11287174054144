import { createStore } from 'vuex'
import axios from "axios"
import { fetchLocalData } from '@/services/data.service.js'
import Data from '@/assets/data/data.json'

export default createStore({
  state: {
    isScroll: false,
    dataList: Data,
  },
  mutations: {
    isScrollingFunction(state) {
      this.state.isScroll = true;
    },
    isScrollingFunctionReverse(state) {
      this.state.isScroll = false;
    },
    navigateToDetailsPageMutation(state, data){
      state.details = data
    }
  },
  modules: {
  }
})
