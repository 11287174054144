<template>
  <div class="header" id="home">
    <div class="row">
      <div data-aos="fade-down-right" class="left__card">
            <div className='last_name'>
                <section></section>
                <h1>Elachagui</h1>
            </div>
            <p>Hello,</p>
            <p>My name is <span>Elachagui</span></p>
            <p>I’m a <span>fullstack</span></p>
            <p>Web & Mobile Developer</p>
            <vue-writer className='sub__text' :array="['As a fullstack developer i really like new challenges & building good experiences']"  :delay="10000" :start="2000" :eraseSpeed="30" :typeSpeed="50" />
            <div class="button__header">
              <a  href="#" v-scroll-to="'#contact'">
                <div class="hire__me">
                  <button>Hire Me</button>
                </div>
              </a>
              <a href="AyyoubElachagui.pdf" download="AyyoubElachagui">
              <div class="discover">
                <button>Discover</button>
              </div>
            </a>
            </div>
            <div class="icons__header">
              <a href="https://www.linkedin.com/in/ayyoub-el-achagui-39922a194/" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-linkedin icons"></i>
              </a>
              <a href="https://github.com/AyyoubElachagui" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-github icons"></i>
              </a>
              <a href="https://gitlab.com/ayyoub.elachagui" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-gitlab icons"></i>
              </a>
              <a href="https://bitbucket.org/AchgauiPro/" target="_blank" rel="noopener noreferrer">
                <i class="fa fa-bitbucket icons"></i>
              </a>
        </div>
      </div>
      <div data-aos="fade-up-left" class="right__card">
        <img src="https://firebasestorage.googleapis.com/v0/b/elachagui-portfolio.appspot.com/o/img_header.png?alt=media&token=7f7f5d37-f855-4b2f-b596-8720e03f26c4&_gl=1*1mxxs1q*_ga*NjA5NTgyNTQ3LjE2OTQ0NzU3Njc.*_ga_CW55HF8NVT*MTY5NzI4OTI1Ni41NC4xLjE2OTcyOTE2MjUuMTguMC4w" class="img" alt="Elachagui" width="600" height="500">
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Welcome",
}
</script>

<style scoped>

.icons{
  width: 50px;
  height: 50px;
  color: white
}

.header{
  width: 98%;
  height: 96vh;
  background-color: #0A4EFF;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 2vh;
  border-radius: 20px;
  background-image: url('/svg-animation-stroke.svg');
  background-repeat:no-repeat;
  background-position: 55vw;
  padding: 90px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img{
  width: 600px;
  height: 500px;
}

.row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 20px 0px;
}
.last_name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 200px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.last_name > h1 {
  margin-left: 10px;
  color: white;
  display: inline;
  font-size: 23px;
  font-weight: 500;
}

div > section {
  width: 80px;
  height: 4px;
  background-color: black;
  border-radius: 3px;
}

.sub__text{
    width: 330px;
    height: 50px;
  color: white;
  font-size: 12px;
  font-weight: 300;
}

.left__card{
    width: 50%;
}



.left__card > p {
  font-size: 34px;
  margin-bottom: 3px;
  color: white;
  font-weight: 700;
}

.left__card > p > span {
  color: black;
}

.button__header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 300px;
  margin-top: 50px;
}

button{
  background-color: transparent;
  color: white;
  border: none;
  font-weight: 300;
  font-size: 16px;
  cursor: pointer;
}

.hire__me{
  padding: 8px 40px;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  background-color: black;
  border: none;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.discover{
  padding: 8px 40px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
  border-radius: 20px;
  text-align: center;
  background-color: #0A4EFF;
  border: none;
}

.discover > button > a {
  color: white;
  text-decoration: none;
}

.icons__header{
  margin-top: 40px;
}

.icons__header > a > i{
  font-size: 24px;
  margin-right: 15px;
}



@media screen and (max-width: 850px) {
  .sub__text{
    width: 100%;
  }

  .button__header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
  }

  .right__card{
    display: none;
  }
  .left__card{
    width: 100%;
  }
  .left__card > p {
    font-size: 30px;
  }
  .icons__header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .icons__header > a > i{
    text-align: center;
  margin-right: 0px;
}

  .button__header{
    display: block;
    margin-left: 32px;
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0px;
  }
  div > section {
    width: 60px;
  }
}


@media screen and (min-width: 851px){
  .right__card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 40%;
  }
  div > section {
    width: 60px;
  }
  .left__card{
    width: 100%;
  }
  .right__card > img {
    width: 400px;
    height: 300px;
  }
  .left__card > p {
    font-size: 28px;
  }
}

@media screen and (min-width: 1100px){
  .right__card > img {
    width: 550px;
    height: 450px;
  }
  div > section {
    width: 60px;
  }
}

@media screen and (min-width: 851px) and (max-width: 983px){
  .left__card > p {
    font-size: 24px;
  }
  div > section {
    width: 60px;
  }
}

@media screen and (max-width: 500px){
  .header{
    background-position:  0vw;
  }
  .icons__header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
  .left__card > p {
    font-size: 28px;
  }
  div > section {
    width: 60px;
  }

  .button__header{
    margin-top: 20px;
  }

  .last_name{
    margin-top: 120px;
  }
  
  .button__header{
    display: block;
    margin-left: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .header{
    height: fit-content;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  div > section {
    width: 60px;
  }
}

</style>