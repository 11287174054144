<template>
    <div class="section__experience" id="experience">
        <div class="experience__body">
            <div data-aos="zoom-in-up" class="title__section">
                <h3>Experiences</h3>
            </div>
            <div class="row">
                <div v-for="item in this.$store.state.dataList.experience" :key="item.companyName">
                    <div class="experience__card" data-aos="zoom-in-up">
                        <p>{{item.fonction}}</p>
                        <p>{{item.companyName}}</p>
                        <p>{{item.contract}}</p>
                        <p>{{ item.city }}, {{ item.country }}</p>
                        <p>{{item.startDate}} - {{ item.endDate }}</p>
                        <p v-if="item.technologies.length > 0">Technologies</p>
                        <div class="row_technologies">
                            <div v-for="i in item.technologies">
                                <p>{{ i }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Experience",
}
</script>
<style scoped>
h3{
    color: white;
    position: relative;
    margin: 60px auto;
    font-size: 30px;
    top: 10px;
    width: 185px;
    line-height: 25px;
    border-bottom: 4px Solid black;
    margin-bottom: 60px;
}

.experience__body{
    width: 100%;
}

.section__experience{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 80px 100px;
    background: linear-gradient(80deg, #0A4EFF 0%, #9ab6fb 50%, #0A4EFF 100%);
    animation: animatedgradient 10s ease infinite alternate;
    background-size: 300% 300%;
    margin-top: 40px;
}

.row_technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.row_technologies > div {
    margin-right: 10px;
    padding: 5px 10px;
    border: 0.5px solid #d5e6f6;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    background-color: #EEF3F8;
    color: #0A4EFF;
    border-radius: 50px;
    margin-top: 10px;
}

.row_technologies > div:hover {
    background-color: #0A4EFF;
    color: white;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.experience__card{
    width: 42vw;
    height: 90%;
    margin: 20px auto;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.experience__card > p:nth-child(1){
    font-weight: 600;
    font-size: 20px;
    color: #0A4EFF;
}

.experience__card > p:nth-child(2){
    font-weight: 600;
    margin-top: 10px;
    font-size: 18px;
    color: black;
}

.experience__card > p:nth-child(3){
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.8);
}
.experience__card > p:nth-child(4){
    font-weight: 300;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
}

.experience__card > p:nth-child(5){
    font-weight: 300;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
}

.experience__card > p:nth-child(6){
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 821px) and (max-width: 1024px){
    .experience__card{
        width: 35vw;
    }
}

@media screen and (max-width: 820px){
    .section__experience{
        padding: 40px 20px;
    }

    .row {
        display: flex;
        justify-content: center;
    }
    .experience__card{
        width: 90vw;
    }
}

@keyframes animatedgradient {
  0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0 50%;
    }
}

</style>