import { createRouter, createWebHistory, useRoute  } from 'vue-router'
import Home from '@/views/Home.vue'
import ProjectDetails from '@/views/ProjectDetails.vue'
import NotFound from '@/views/NotFound.vue'
import Data from '@/assets/data/data.json'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/project/:name',
    name: 'Project',
    component: ProjectDetails 
  },
  {
    path: '/404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    let data = Data.projects.filter(project => project.slug == to.params.name)[0]
    // if(!data){
    //   next('/404');
    // }
    // next();
    if (to.matched.length === 0 || !data) {
      // If the route is not found, redirect to the 404 page
      next('/404');
    } else {
      next();
    }
});

export default router
