<template>
    <div class="section__footer"  data-aos="zoom-in-up">
        <header>
            <div class="logo__text">
                <a href="#" v-scroll-to="'#home'"><h1>Elachagui<span>.</span></h1></a>
                
            </div>

            <div class="navigation__links">
                <nav>
                    <ul>
                        <li>
                            <a href="#" v-scroll-to="'#home'">Home</a>
                        </li>
                        <li>
                            <a href="#"  v-scroll-to="'#about'">About</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#skills'">Skills</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#experience'">Experiences</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#projects'">Projects</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#contact'">Contact me</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="icons__header">
                <a href="https://www.linkedin.com/in/ayyoub-el-achagui-39922a194/" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-linkedin icons"></i>
                </a>
                <a href="https://github.com/AyyoubElachagui" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-github icons"></i>
                </a>
                <a href="https://gitlab.com/ayyoub.elachagui" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-gitlab icons"></i>
                </a>
                <a href="https://bitbucket.org/AchgauiPro/" target="_blank" rel="noopener noreferrer">
                    <i class="fa fa-bitbucket icons"></i>
                </a>
            </div>
        </header>
        <p>Elachagui © 2023, All rights reserved.</p>
    </div>
</template>
<script>

export default {
    name: "Footer",
}
</script>

<style scoped>
.section__footer{
    padding: 20px 100px;
    border: none;
    border-top: 1px solid #F5F6F8;
    background-color: black;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
}

a{
    text-decoration: none;
}

h1{
    color: white;
}

h1 > span {
    color: #0A4EFF;
}

p{
    color: white;
    text-align: center;
    font-size: 12px;
}

nav > ul > li{
    display: initial;
}

nav > ul > li > a {
    text-decoration: none;
    color: white;
    margin-right: 20px;
}

nav > ul > li > a:first {
    margin-left: 0px;
}

.icons__header > a > i{
  font-size: 24px;
  margin-right: 15px;
  color: white;
}

.icons__header > a:nth-child(3) > i:hover{
  color: #db4128;
}
.icons__header > a:nth-child(2) > i:hover{
  color: black;
  background-color: #F5F6F8;
  padding: 1px;
  border-radius: 50%;
}

.icons__header > a:nth-child(1) > i:hover{
  color: #0A4EFF;
}

.icons__header > a:nth-child(4) > i:hover{
  color: #2684ff;
}

.contact__button > button {
    padding: 8px 30px;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    background-color: black;
    border: none;
    color: white;
    cursor: pointer;
}


.navigation__links{
    display: block;
}

@media screen and (max-width: 850px) {
    header{
        display: inline;
        padding: 20px 30px;
    }
    nav > ul{
        margin-bottom: 50px;
    }

    nav > ul > li{
        display: block;
        font-size: 30px;
        text-align: center;
        margin: 5px;
    }

    nav > ul > li > a {
        color: white;
        margin-left: 0px;
        font-size: 20px;
    }

    .icons__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0px;
    }
    .icons__header > a > i{
        margin-right: 0px;
    }
    
}

@media screen and (max-width: 500px) {
    header{
        display: inline;
        padding: 20px 30px;
    }
}

</style>