<template>
    <div class="section__skills" id="skills">
        <div class="skills__body">
            <div data-aos="zoom-in-up" class="title__section">
                <h3>Skills</h3>
            </div>
            <div class="laptop">
                <div class="row-stack" data-aos="zoom-in-up">
                    <div>
                        <p :class="{ 'active': stackSelectedName === 'all' }" v-on:click="stackSelected('all')">All</p>
                    </div>
                    <div v-for="item in this.$store.state.dataList.stack" :key="item.name">
                        <p v-on:click="stackSelected(item.name)" :class="{ 'active': stackSelectedName === item.name }">{{
                            item.name }}</p>
                    </div>
                </div>
            </div>

            <div class="phone">
                <small><i>Select stack</i></small>
                <select class="select-tech" v-on:change="stackSelected">
                    <option value="all" selected>All</option>
                    <option v-for="item in this.$store.state.dataList.stack" :key="item.name" :value="item.name">{{
                        item.name }}</option>
                </select>
            </div>
            <div class="row-language">
                <div v-for="item in dataList ?? this.$store.state.dataList.skills" :key="item.language">
                    <div class="skills__card" data-aos="zoom-in-up">
                        <div>
                            <img :src="item.image" :alt="item.language">
                        </div>
                        <div>
                            <h5>{{ item.language }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Skills",
    data() {
        return {
            dataList: null,
            stackSelectedName: 'all',
        }
    },
    methods: {
        stackSelected(stack) {
            this.stackSelectedName = stack
            if (stack === 'all' || (stack.target && stack.target.value == 'all')) {
                this.dataList = this.$store.state.dataList.skills
            } else {
                if (stack.target) {
                    this.stackSelectedName = stack.target.value
                    this.dataList = this.$store.state.dataList.skills.filter(skill => skill.stack === stack.target.value);
                    return;
                }
                this.dataList = this.$store.state.dataList.skills.filter(skill => skill.stack === stack);
            }
        }
    }
}
</script>

<style scoped>

.laptop {
    display: block;
}

.phone {
    display: none;
}

h3 {
    position: relative;
    margin: 60px auto;
    font-size: 30px;
    top: 10px;
    width: 80px;
    line-height: 25px;
    border-bottom: 4px Solid #0A4EFF;
    margin-bottom: 60px;
}

.skills__card {
    margin: 10px;
    padding: 4px 15px;
    background-color: transparent;
    border: 1px #adadad solid;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skills__card>div>img {
    width: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.skills__card>div>h5 {
    font-size: .875rem;
    text-align: center;
    padding: 10px;
    font-weight: 400;
    line-height: 1.25rem;
    color: #4f4f4f;
}


.section__skills {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 80px 100px;
    background-color: #F5F6F8;
}


.row-language {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.row-stack {
    width: 80vw;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around
}

.select-tech {
    padding: 10px;
    width: 100%;
    border: none;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    appearance: none;
    text-align: center;
}

.row-stack>div {
    cursor: pointer;
    margin: 5px;
}

.row-language>div>p:hover {
    background-color: #0A4EFF66;
    color: white;
}

.active {
    background-color: #000000;
    color: white;
}

p {
    padding: 6px 12px;
    margin: auto;
    width: 140px;
    text-align: center;
    border-radius: 20px;
    color: #4f4f4f;
    font-size: 14px;
    background-color: transparent;
    border: 1px #Adadad solid;
    margin: 20px auto;
}

@media screen and (max-width: 426px) {
    .laptop {
        display: none;
    }

    .skills__body {
        height: fit-content;
    }

    .phone {
        display: block;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 425px) {
    .row-language {
        width: 80vw;
        justify-content: space-between
    }

    .skills__body {
        height: fit-content;
    }
}

@media screen and (max-width: 820px) {
    .section__skills {
        padding: 40px 20px;
    }

    .skills__body {
        height: fit-content;
    }
}
</style>