<template>
    <div class="section__projects" id="projects">
        <div class="projects__body">
            <div data-aos="zoom-in-up">
                <h3>Projects</h3>
            </div>
            <div class="laptop">
                <div class="row-stack" data-aos="zoom-in-up">
                    <div>
                        <p :class="{ 'active': stackSelectedName === 'all', 'unactive' : stackSelectedName !== 'all' }" v-on:click="stackSelected('all')">All</p>
                    </div>
                    <div v-for="item in this.$store.state.dataList.projects_stack" :key="item.name">
                        <p v-on:click="stackSelected(item.name)" :class="{ 'active': stackSelectedName === item.name, 'unactive' : stackSelectedName !== item.name}">{{
                            item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="phone">
                <small><i>Select stack</i></small>
                <select class="select-tech" v-on:change="stackSelected">
                    <option value="all" selected>All</option>
                    <option v-for="item in this.$store.state.dataList.projects_stack" :key="item.name" :value="item.name">{{
                        item.name }}</option>
                </select>
            </div>
            <div class="p__md">
                <div v-for="project in this.dataList ?? this.$store.state.dataList.projects" :key="project.name" class="project">
                    <div class="project-card">
                        <img :src="project.image" :alt="project.name" class="project-cover">
                        <div class="project-card-bottom">
                            <h5 class="project-name">{{ project.name }}</h5>
                            <div class="row_technologies">
                                <div v-for="tech in project.technologies">
                                    <p>{{ tech }}</p>
                                </div>
                            </div>
                            <p class="project-description">{{ project.description }}</p>
                            <div class="view-project">
                                <a v-if="project.slug == null" :href="project.link" target="_blank" class="link">view project</a>
                                <router-link v-else @click="handleClick(project)" :to="`/project/${project.slug}`" class="link">view project</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Projects",
    data(){
        return {
            stackSelectedName: 'all',
            dataList: null,
        }
    },
    methods: {
        handleClick(data){
            this.$store.dispatch('navigateToDetailsPage', data)
        },
        stackSelected(stack){
            this.stackSelectedName = stack
            if (stack === 'all' || (stack.target && stack.target.value == 'all')) {
            console.log("**************** :: stack :: "+stack); 
                this.dataList = this.$store.state.dataList.projects
            } else {
                if (stack.target) {
                    this.stackSelectedName = stack.target.value
                    this.dataList = this.$store.state.dataList.projects.filter(project => project.stack.includes(stack.target.value));
                    return;
                }
                this.dataList = this.$store.state.dataList.projects.filter(project => project.stack.includes(stack));
            }
        }
    }
}
</script>

<style scoped>
h3 {
    position: relative;
    margin: 20px auto;
    font-size: 30px;
    top: 40px;
    width: 123px;
    line-height: 25px;
    border-bottom: 4px Solid #0A4EFF;
}

div>h3 {
    font-size: 30px;
    margin-bottom: 80px;
}

.section__projects {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0px;
}

.row_technologies {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.row_technologies>div {
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    height: 30px;
    font-size: 12px;
    border: 0.5px solid #d5e6f6;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    background-color: #EEF3F8;
    color: #0A4EFF;
    border-radius: 50px;
}

.row_technologies>div:hover {
    background-color: #0A4EFF;
    color: white;
}

.projects__body {
    margin-top: 5vh;
    padding: 0px auto;
    width: 100%;
}


.p__md {
    width: 80%;
    margin: 30px auto;
    padding: 0px 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.project {
    width: 30%;
    margin: 20px auto;
}

.project-card {
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
    display: grid;
}

.project-description {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    margin-bottom: 10px;
}

.project-card-bottom {
    padding: 10px 20px;
    display: grid;
    justify-content: space-between;
}

.view-project {
    height: 35px;
    border: none;
    width: fit-content;
    margin: 5px 0px;
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #000000;
}

.view-project>.link {
    text-decoration: none;
    color: white;
    font-size: 14px;
}
.project-name {
    font-size: 22px;
    font-weight: bold;
    color: black;
    margin-bottom: 10px;
}

.project-cover {
    width: 100%;
    height: 145px;
    object-fit: cover;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.select-tech {
    padding: 10px;
    width: 100%;
    border: none;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    appearance: none;
    text-align: center;
}

.project-tech {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.project-tech>h5 {
    margin: 10px 5px;
    padding: 2px 4px;
    background-color: aliceblue;
    border-radius: 5px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
}

.laptop {
    display: flex;
    justify-content: center;
}

.phone {
    display: none;
}

.row-stack{
    width: 60vw;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around
}

.row-stack>div {
    cursor: pointer;
    margin: 5px;
}

.unactive {
    padding: 6px 12px;
    margin: auto;
    width: 140px;
    text-align: center;
    border-radius: 20px;
    color: #4f4f4f;
    font-size: 14px;
    background-color: transparent;
    border: 1px #Adadad solid;
    margin: 20px auto;
}


.active {
    background-color: #000000;
    color: white;
    padding: 6px 12px;
    margin: auto;
    width: 140px;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
    border: 1px #Adadad solid;
    margin: 20px auto;
}

@media screen and (max-width: 426px) {
    .laptop {
        display: none;
    }

    .phone {
        display: flex;
        flex-direction: column;
        margin: 20px;
    }
}

@media screen and (max-width: 425px) {
    .row-language {
        width: 80vw;
        justify-content: space-between
    }
}

@media screen and (max-width: 820px) {

    .p__md {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .project {
        width: 90%;
    }

}

@keyframes animatedgradient {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}
</style>

<!-- 
{
    "name": "Sertect Innov",
    "description": "J'ai développé le site web de Sertect Innova, offrant une vitrine en ligne pour leur expertise en transformation des systèmes d'information et en sécurité informatique",
    "image": "projects/sertect-innov.png",
    "link": null,
    "slug": "sertect-innov",
    "technologies": [
        "Php",
        "Php Mailer",
        "Html5",
        "Css3",
        "Netlify"
    ],
    "details": {
        "images": [
            {
                "imagePath": "",
                "imageAlt": ""
            },
            {
                "imagePath": "",
                "imageAlt": ""
            },
            {
                "imagePath": "",
                "imageAlt": ""
            }
        ],
        "decription": ""
    }
} -->

<!-- 
    ,
        {
            "name": "Mbancking",
            "description": "A secure mobile application for quickly exchanging money from Moroccan Dirhams (DH) to USD, CAD, GBP, or EUR, with favorable exchange rates.",
            "image": "projects/mbancking.png",
            "link": "https://github.com/AyyoubElachagui/mbanking",
            "slug": null,
            "technologies": [
                "Java",
                "Xml",
                "Sqlite"
            ]
        },
        {
            "name": "Sertect Innov",
            "description": "I developed the website for Sertect Innova, providing an online showcase for their expertise in information system transformation and computer security.",
            "image": "projects/sertect-innov.png",
            "link": "https://sertect-inno.netlify.app/",
            "slug": null,
            "technologies": [
                "Php",
                "Php Mailer",
                "Html5",
                "Css3",
                "Netlify"
            ]
        }
 -->