<template>
    <div class="section__certificate" id="certificate">
        <div class="certificate__body">
            <div data-aos="zoom-in-up">
                <h3>Certifications</h3>
            </div>
            <div class="p__md">
                <div data-aos="zoom-in-down" class="img-responsive"  v-for="item in this.$store.state.dataList.certifications" :key="item.picture">
                    <a :href="item.pdf" target="_blank">
                        <img :src="item.picture" alt="el achagui">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Certificate"
}
</script>

<style scoped>
h3 {
    position: relative;
    margin: 0px auto;
    font-size: 30px;
    top: 40px;
    width: 208px;
    line-height: 25px;
    border-bottom: 4px Solid #0A4EFF;
}

div>h3 {
    font-size: 30px;
    margin-bottom: 60px;
}

.section__certificate {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 80px 0px;
}


.certificate__body {
    margin-top: 5vh;
    padding: 30px auto;
    width: 100%;
    background-color: rgb(255, 255, 255);
}


.p__md {
    width: 80%;
    margin: 30px auto;
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
}


div>h3 {
    margin-bottom: 0px;
}

.p__md>div {
    width: 45%;
    margin: 10px auto ;
}

.p__md > div > a {
    height: 100%;
    padding: 10px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.p__md > div > a:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
}

img{
    width: 100%;
}

@media screen and (max-width: 820px) {
    .certificate__body {
        margin-top: 0px;
    }

    .p__md {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .p__md>div {
        width: 90%;
    }

}
</style>