<template>
    <main>
        <div class="main-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/elachagui-portfolio.appspot.com/o/404.png?alt=media&token=9838d6aa-f193-4845-bf24-6f01b092e5ba&_gl=1*1r9d86s*_ga*NjA5NTgyNTQ3LjE2OTQ0NzU3Njc.*_ga_CW55HF8NVT*MTY5NzY1NjA0NC41Ni4xLjE2OTc2NTYyNDMuNTguMC4w" alt="ayyoub el achagui">
        </div>
        <div class="main-text">
            <h3>ERROR CODE: 404</h3>
            <h1>OOOPS!!</h1>
            <h2>The page you are looking for may have been moved, deleted, or possibly never existed.</h2>
            <router-link to="/">Go Home</router-link>
        </div>
    </main>
</template>
<script>
export default {
    name: "NotFound"
}
</script>
<style>
    main{
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 10% ;
        gap: 40px;
    }
    .main-text{
        width: 50%;
        text-align: start;
        padding: 10px;
        margin: auto 10px;
    }
    .main-text h3{
        font-size: 15px;
        color:#ABB0B3;
        text-transform: capitalize;
        letter-spacing: 2px;
        margin-bottom: 16px;
    }
     .main-text h1{
        font-size: 57px;
        color:#121212;
        font-weight: bold;
        margin-top: 12px;
     }

     .main-text h2{
        font-size: 33px;
        color: #121212;
        font-weight: 400; 
        margin-bottom:40px;
        line-height: 48px;
    

     }
     .main-text a{
        padding: 10px 20px;
        background: linear-gradient(90deg,#fef506,#ff6575,#ae4df9,#029aff);
        background-size: 500%;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        animation: color 5s infinite alternate;

     }
     @keyframes color {
        0%{
            background-position: left;
        }
        100%{
            background-position: right;
        }
        
     }
     .main-text a:hover{
        animation: color 1s infinite alternate;
     }
    .main-image{
        display: flex;
        z-index: -1;
        justify-content: center;
        width:50%;
        height: 90vh;
        background-color: transparent;

    }
    @keyframes move 
    {
     0%{
        transform: translateY(-10px);
     }   
     25%{
        transform: translateX(10px);
     }
     50%{
        transform: translateY(10px);
     }
     75%{
        transform: translateX(-10px);
     }
     100%{
        transform: translateY(-10px);
     }
    }
    .main-image img{
        width: 720px;
        animation: move 10s infinite linear;
    }

    

@media screen and (max-width: 500px){
    main{
        display: block;
    }
    
    .main-text{
        width: 100%;
    }

    
    .main-text h3{
        font-size: 12px;
    }

    
    .main-text h1{
        font-size: 34px;
     }

     
     .main-text h2{
        font-size: 23px;

     }

     .main-image{
        width: 100%;
        height: 200px;
        margin-top: 40px;
     }

    .main-image img{
        width: 200px;
        height: 150px;
    }
}

@media screen and (max-width: 850px) {
    main{
        display: block;
    }
    
    .main-text{
        width: 100%;
    }

    
    .main-text h3{
        font-size: 12px;
    }

    
    .main-text h1{
        font-size: 34px;
     }

     
     .main-text h2{
        font-size: 23px;

     }

     .main-image{
        width: 100%;
        height: 200px;
        margin-top: 40px;
     }

    .main-image img{
        width: 200px;
        height: 150px;
    }
}

</style>