<template>
  <div class="navbar__top">
    <AppBarNavigation />
  </div>
  <Welcome />
  <AboutMe />
  <Skills />
  <Experience />
  <Projects />
  <Certificate />
  <Contact />
  <Footer />
</template>

<script>
// @ is an alias to /src
import AppBarNavigation from '@/components/AppBarNavigation.vue'
import Welcome from '@/components/Welcome.vue'
import AboutMe from '@/components/AboutMe.vue'
import Skills from '@/components/Skills.vue'
import Experience from '@/components/Experience.vue'
import Projects from '@/components/Projects.vue'
import Certificate from '@/components/Certificate.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    AppBarNavigation,
    Welcome,
    AboutMe,
    Skills,
    Experience,
    Projects,
    Certificate,
    Contact,
    Footer
  },
}
</script>

<style scoped>


.navbar__top{
  position: fixed;
  width: 100vw;
  margin: 0px;
  z-index: 9999;
}



</style>
