<template>
  <router-view />
</template>

<script>
import Home from "@/views/Home.vue"

export default {
  name: "App",
  components: {
    Home,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      window.scrollY >= 10 ? this.$store.commit('isScrollingFunction') : this.$store.commit('isScrollingFunctionReverse');
    },
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #F5F6F8;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}

body::-webkit-scrollbar-thumb {
  background-color: #0A4EFF;
  outline: 1px solid white;
  border-radius: 10px;
}

textarea::-webkit-scrollbar {
  width: 5px;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #0A4EFF;
  outline: 1px solid white;
  border-radius: 10px;
}
</style>
