<template>
    <div class="contact" id="contact">
        <div class="contact__body">
            <div data-aos="zoom-in-up" class="title__section">
                <h3>Get in touch</h3>
            </div>
        </div>
        <p data-aos="zoom-in-up">Let’s talk about you next project idea.<br>Feel free to message me and i will reply in matter of hours.</p>

        <form class="row"  data-aos="zoom-in-up" @submit.prevent="sendEmail">
            <div class="col-lg-6">
                <input type="text" class="input-form" placeholder="Full name" v-model="fullName">
                <input type="email" class="input-form" placeholder="Email" v-model="email">
            </div>
            <div class="col-lg-12">
                <input type="text" class="input-form" placeholder="Subject | Project idea" v-model="subject">
            </div>
            <div class="col-lg-12">
                <textarea class="input-form" placeholder="Message..." v-model="message"></textarea>
            </div>
            
            <div class="col-lg-12">
                <button type="submit" class="btn-form">
                    <div class="svg">
                        <span>Send</span> 
                        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#ffffff" fill="none" width="18px" height="18px">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M38.61,54.93,27.94,35.57,9.08,25.38a1,1,0,0,1,.2-1.8L54.08,8.64a1,1,0,0,1,1.27,1.27L40.41,54.73A1,1,0,0,1,38.61,54.93Z"></path>
                                <line x1="55.13" y1="8.91" x2="27.94" y2="35.57" stroke-linecap="round"></line>
                            </g>
                        </svg>
                    </div>
                </button>
            </div>
        </form>

    </div>

</template>

<script>
    import emailjs from '@emailjs/browser';
    
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    name: "Contact",
    data(){
        return{
            fullName: '',
            email: '',
            subject: '',
            message: '',
        }
    },
    methods: {
    sendEmail(e) {
        e.preventDefault()
        try {
            
            if(this.fullName == '' || this.email == '' || this.subject == '' || this.message == ''){
                toast.error("Please fill all contact form before click on send button");
                return;
            }
            
            emailjs.send("service_80s7wss","template_v353ah5",{
                to_name: "yes",
                from_name: this.subject.trim(),
                test: "new variable",
                name: this.fullName.trim(),
                email: this.email.trim(),
                reply_to: "q",
                message: this.message.trim(),
                }, 
            '5w24LJcA1Pp27zKB-');
            toast.success("you message has been send successfuly i will replay you as a soon possible, thanks");
            this.fullName = '';
            this.email = '';
            this.subject = '';
            this.message = '';
        } catch(error) {
            toast.error("can't you send mail know, please try again later");
        }
    }
  }
}
</script>
<style scoped>

input, textarea{
    outline: none;
}

.contact{
  width: 98%;
  background-color: black;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 2vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 90px 100px; 
}



h3{
    color: white;
    position: relative;
    margin: 30px auto;
    font-size: 30px;
    top: 10px;
    width: 185px;
    line-height: 25px;
    font-weight: 600;
    border-bottom: 4px Solid #0A4EFF;
    margin-bottom: 60px;
}

p{
    color: white;
    margin: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.row{
    display:grid;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 40px;
}

.row > div{
    margin-top: 20px;
}

.input-form{
    background-color: transparent;
    padding: 20px;
    border: 2.5px solid #9B9B9B;
    border-radius: 8px;
    margin: 0px 10px;
    color: aliceblue;
}



.col-lg-6 > input {
    width: 300px;
    height: 50px;
}
.col-lg-12 > input {
    width: 620px;
    height: 50px;
}

textarea {
    width: 620px;
    height: 150px;
}

.input-form:focus{
    border-color: #0A4EFF;
}

textarea:focus {
    border-color: #0A4EFF;
}

.btn-form{
    width: 620px;
    height: 55px;
    border: none;
    border-radius: 8px;
    margin: 0px 10px;
    color: aliceblue;
    background-color: #0A4EFF;
}
.svg{
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg > span {
    margin-right: 10px;
}


@media screen and (max-width: 640px) {

    input:focus{
    border-color: #0A4EFF;
}

textarea:focus {
    border-color: #0A4EFF;
}
    .contact{
        padding: 80px 20px;
    }
    .row{
        display: block;
        padding: 0px ;
    }
    .input-form{
        margin: 0px 0px;
        padding: 20px;
    }
    .col-lg-6 > input{
        width: 100%;
        margin-top: 20px;
    }
    .col-lg-12 > input{
        width: 100%;
    }
    textarea {
        width: 100%;
        height: 150px;
    }
    .btn-form{
        width: 100%;
        margin: 0px 0px;
    }
 }

</style>