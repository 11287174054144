<template>
    <div class="section__about" id="about">
        <div class="about__body">
            <div data-aos="zoom-in-up">
                <h3>About</h3>
            </div>
            <div class="p__md">
                <div data-aos="zoom-in-down" class="img-responsive">
                    <img src="https://firebasestorage.googleapis.com/v0/b/elachagui-portfolio.appspot.com/o/me_%201.png?alt=media&token=53ad143c-7168-41cd-a11d-b333e6e29984&_gl=1*1ycv1gr*_ga*NjA5NTgyNTQ3LjE2OTQ0NzU3Njc.*_ga_CW55HF8NVT*MTY5NzI4OTI1Ni41NC4xLjE2OTcyOTQ0NTAuMzIuMC4w" alt="ayyoub el achagui">
                </div>
                <div data-aos="fade-left">
                    <p class="about__prs">Hello, I'm <b>Ayyoub Elachagui</b>, a Software Engineer based in Rabat, Morocco.
                    </p>
                    <br>
                    <p>As a Software Engineer, I would like to bring technology to make life easier and increase
                        productivity. I also want to create great products which have good design architecture, high
                        scalability and easy to maintain.</p>
                    <br>
                    <p>I'm looking for opportunities to participate in challenging projects!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutMe"
}
</script>

<style scoped>
.about__prs {
    initial-letter: 1.5rem;
    font-size: 15px;
    font-weight: 300;
}


p {
    font-size: 13px;
    font-weight: 400;
}

h3 {
    position: relative;
    margin: 0px auto;
    font-size: 30px;
    top: 40px;
    width: 95px;
    line-height: 25px;
    border-bottom: 4px Solid #0A4EFF;
}

div>h3 {
    font-size: 30px;
}


.section__about {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 80px 0px;
}


.about__body {
    margin-top: 10vh;
    padding-bottom: 30px;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.p__md {
    width: 80%;
    height: 60vh;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
}


.img-responsive {
    width: 50%;
    display: grid;
    justify-content: center;
}

.img-responsive > img {
    width: 300px;
    height: 400px;
    object-fit: cover;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}


.p__md>div:last-child {
    width: 50%;
    margin: auto 0;
}

@media screen and (max-width: 820px) {
    .about__body {
        margin-top: 0px;
    }

    .img-responsive > img{
        width: 150px;
        height: 200px;
        margin-top: 100px;
    }

    img {
        width: 100px;
    }

    .p__md {
        width: 90%;
        height: 80vh;
    }

    .p__md>div {
        width: 100%;
        margin: 20px auto;
    }

    .p__md>div:last-child {
        width: 100%;
        margin: 20px auto;
    }

    .img-responsive {
        display: grid;
        justify-content: center;
    }
}
</style>