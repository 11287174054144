import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueWriter from "vue-writer"
var VueScrollTo = require('vue-scrollto')

createApp(App).use(store).use(router).use(VueAxios, axios).use(AOS.init()).use(VueScrollTo, {
    container: "body",
    duration: 2000,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
}).use(VueWriter).mount('#app')
